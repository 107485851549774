import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { RiFacebookCircleFill } from 'react-icons/ri'
import ContactBanner from '../components/ContactBanner'



export default function Ourwork() {
    const data = useStaticQuery(graphql`
    query {
        contentfulOurWorkGallery {
          galleryTitle
          description
          images {
            fluid {
                ...GatsbyContentfulFluid
            }
          }
        }
        contentfulHeader {
            facebookLink
          }
      }
    `)

    const { images, galleryTitle, description } = data.contentfulOurWorkGallery
    const { facebookLink } = data.contentfulHeader
    const imageGallery = []

    function addImagesToGalleryObject() {
        images.map(image => {
            return imageGallery.push({
                original: image.fluid.src,
                thumbnail: image.fluid.src
            })
        })
    }

    addImagesToGalleryObject()

    return (
        <Layout>
            <SEO title={galleryTitle} description={description} />
            <div className="bg-gray-100">
                <div className="flex flex-col items-center py-10">
                    <div className="text-blue-900 text-4xl font-extrabold">
                        {galleryTitle}
                    </div>
                    <p className="text-blue-900 md:text-base text-sm">{description}</p>
                </div>

                <ImageGallery className="image-gallery-slide img w-30" items={imageGallery} lazyLoad={true} />
                <div className="flex flex-col items-center py-6">
                    <div className="text-blue-600">
                        <a href={facebookLink} target="_blank" rel="noreferrer"> <RiFacebookCircleFill className="w-40 h-10 hover:text-indigo-300 cursor-pointer" /></a>
                    </div>
                    <div className="text-lg text-center text-blue-800">Follow us on Facebook for more content.</div>
                </div>
            </div>
            <ContactBanner />
        </Layout >
    )
}



// useEffect(() => {
//     axios.get(`graph.facebook.com/v8.0/jstdl/photos/`, {
//         params: {
//           access_token: ``

//         },
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             "Access-Control-Allow-Origin": "*",
//             "Access-Control-Allow-Credentials": true,
//         }
//       })
//     .then(res => {
//       const page = res.data;
//       setPageData(page)
//     })
// })

// console.log(pageData)